import UnitsCarousel from '../../auto/components/ListUnits/Carousel';

(() => {
  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    UnitsCarousel,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
